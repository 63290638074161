* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: none;
  border: none;
  font-family: 'Inter';
  font-size: 25px;
}

html {
  background-color: #D0C097;
}

body {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.cont {
  padding: 50px;
}

.command_panel {
  display: flex;
  justify-content: space-between;
}

.command_panel button {
  padding: 6px 12px;
}

.timer {
  text-align: right;
  margin-bottom: 50px;
}

.question_panel {
  overflow-y: auto;
  display: -webkit-box;
  width: 60%;
  margin-bottom: 50px;
}
.question_panel div {
  border: 1px solid #000;
  width: 40px;
  height: 40px;
}

.main_btn {
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.question p {
  margin-bottom: 50px;
}

ul {
  list-style-type: none;
}

li {
  margin-bottom: 15px;
}
.command_panel {
  position: absolute;
  bottom: 50px;
  right: 50px;
}
.command_panel button {
  background-color: transparent;
  border: 1px solid #000;
}
div.sel {
  border: 3px solid #000;
}

.exam {
  font-size: 15px;
  position: absolute;
  top: 10px;
  left: 10px;
}

.hidden {
  display: none;
}

select, .exam_param label, option, .exam_param button {
  font-size: 15px;
}

@media screen and (max-width: 1024px) {
  * {
    font-size: 18px;
  }
}